export class SimularProducto {
    tipoID: number
    ciclosFacturacionID: number
    plazoID: number
    tasa: number
    diaDeCorte: number
    fechaDesembolso: Date
    monto: number
    base: number
    valorDeLaMaquina: number
    fechaGiro: Date

    
    constructor() {
        this.tipoID = 0;
        this.ciclosFacturacionID = 0;
        this.plazoID = 0;
        this.tasa = 0;
        this.diaDeCorte = 0;
        this.fechaDesembolso = new Date();
        this.monto = 0;
        this.plazoID = 0;
        this.base = 0;
        this.valorDeLaMaquina = 0;
        this.fechaGiro = new Date();
    }

    public static compilarDTO(simular: SimularProducto): SimularProducto {
        simular.fechaDesembolso = new Date(simular.fechaDesembolso);
        simular.monto = parseInt(simular.monto.toString())
        simular.valorDeLaMaquina = parseInt(simular.valorDeLaMaquina.toString())
        return simular;
    } 
}