import "./App.css";
import Main from "./app/Home";
import Pagos from "./pages/Pagos";
import Facturas from "./pages/Facturas";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import PayFactura from "./pages/PayFactura";
import PayConfirm from "./pages/PayConfirm";
import { Helmet } from "react-helmet";
import Comprobante from "./pages/Comprobante";
import Login from "./app/CRM/pages/login-crm";
import NotFound from "./pages/notFound";
import Reportes from "./app/CRM/pages/Repotes";
import PreappovedConfirm from "./app/Preaprobados/PreapprovedConfirm";
import PreapprovedFail from "./app/Preaprobados/PreapprovedFail";
import ProtectedRoute from "./utils/protectedRoute/protectedRoute";
import { ELocalStorageItems } from "./utils/localStorage/local-storage-items.enum";
import Simulador from "./app/Simulador/pages/simulador";
import AvisoPrivacidad from "./pages/Aviso-privacidad";
import Politicas from "./pages/Politica-tratamiento-datos";

function App() {

  return (
    <>
      <BrowserRouter>
        <Helmet>
          <title>FinMaq | Financiamos su Maquina</title>
          <meta
            name="description"
            content="Financiamos su maquina: FinMaq es una empresa financiera que acompaña la adquisición de activos productivos para empresas y personas naturales en crecimiento a través de nuestras alianzas estratégicas con distribuidores especializados en Salud, construcción, metalmecánica, maderero, artes gráficas y estética, a nivel nacional."
          />
          <meta property="og:title" content="FinMaq | Financiamos su maquina" />
          <meta property="og:url" content="https://finmaq.com.co" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="%PUBLIC_URL%/finmaqlogo.png" />
          <meta
            name="og:description"
            content="Financiamos su maquina: Fintech para la financiación de su activo productivo."
          />
        </Helmet>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/aviso-privacidad" element={<AvisoPrivacidad />} />
          <Route exact path="/politicas-de-tratamiento-datos" element={<Politicas />} /> 
          <Route exact path="/privacidad" element={<Politicas/>} />
          <Route exact path="/pagos" element={<Pagos />} />
          <Route
            exact
            element={
              <ProtectedRoute redirectPath="/login" />
            }
          >
            <Route exact path="/reportes" element={<Reportes />} />
          </Route>
          <Route exact path="/pagos/:id" element={<Facturas />} />
          <Route exact path="/pagos/:id/:idFact" element={<PayFactura />} />
          <Route exact path="/pagos/confirm/:ref" element={<PayConfirm />} />
          <Route exact path="/comprobante" element={<Comprobante />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/simulador" element={<Simulador/>}/>
          
          <Route
            exact
            path="/preaprobados/confirm"
            element={<PreappovedConfirm />}
          />
          <Route
            exact
            path="/preaprobados/fail"
            element={<PreapprovedFail />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
