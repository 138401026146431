import { Input } from "@material-tailwind/react";
import React from "react";

import FieldProps, { InputTypes } from "./textFieldProps";
import CurrencyFormat from "react-currency-format";


const compilarTextField = ({ model, setModel, campoId, placeholder, type, required, onBlur, icon, onKeyUp }: FieldProps) => {
  return (
    <div className="">
      <div className="text-sm p-0 m-0  text-purple font-semibold">{placeholder}</div>
      {(type === InputTypes.Money || type === InputTypes.Percent) ?
        <FormatInput campoId={campoId} model={model} setModel={setModel} placeholder={placeholder} type={type} onBlur={onBlur} required={required} icon={icon} onKeyUp={onKeyUp} /> :
        <BasicInput campoId={campoId} model={model} setModel={setModel} placeholder={placeholder} type={type} onBlur={onBlur} required={required} icon={icon} onKeyUp={onKeyUp}/>}
    </div>
  );
}


const BasicInput = ({ model, setModel, campoId, placeholder, type, required, onBlur,onKeyUp }: FieldProps) => {
  return <input
    autoComplete='true'
    required={required}
    type={type}
    className='w-full text-blue-gray-700 font-sans font-normal px-3 py-2.5 rounded-[7px] border   focus:outline-none focus:border-2 border-deep-purple-200 focus:border-deep-purple-500'
    placeholder={placeholder}
    value={InputTypes.Date === type? model[campoId]?.toISOString().substring(0,10):model[campoId]}
    onChange={(e) => {
      const esNumero = (type === InputTypes.Number);
      const esFecha = (type === InputTypes.Date);
      let valor:any = esNumero ? parseFloat(e.target.value) : e.target.value;
      valor = esFecha? new Date(valor):valor;
      model[campoId] = valor;
      setModel({ ...model });
    }}
    onKeyUp={onKeyUp}
    onBlur={onBlur}
  />
}

const FormatInput = ({ model, setModel, campoId, placeholder, type, required, onBlur }: FieldProps) => {
  return <CurrencyFormat
    prefix={type === InputTypes.Money ? "$" : ""}
    suffix={type === InputTypes.Percent ? "%" : ""}
    // format={type === InputTypes.Percent ? "#.###%" : undefined}
    value={model[campoId]}
    onValueChange={(values) => {
      const { formattedValue, value, floatValue } = values;
      model[campoId] = value
      setModel({ ...model });
    }}
    className='w-full text-blue-gray-700 font-sans font-normal px-3 py-2.5 rounded-[7px] border focus:outline-none focus:border-2 border-deep-purple-200 focus:border-deep-purple-500'
    required={required}
    placeholder={placeholder}
    color="deep-purple"
    thousandSeparator={(type === InputTypes.Money) ? true : false}
    max={type === InputTypes.Percent ? 100 : null}
  />
}

export default compilarTextField;