import { HttpClientService } from "../../../api/api";
import { EmbedUrl } from "../entities/embedUrl";
import LoginResponse from "../entities/login";
import { PowerBIReports, ReportsByRol , RequestGetToken } from "../entities/powerBIReports";
import { RefreshStatus } from "../entities/refeshStatus";
import { Token } from "../entities/token";
import { TokenPowerBi } from "../entities/tokenPowerBi";
import { Roles } from "../entities/roles";

class CRMService {

    private urlObtenerUrlReporte = (idReporte: string) => {
        return `powerbi/report/${idReporte}`
    }

    private urlObtenerReporteDeGrupo = (idGroup: string, idReporte: string) => {
        return `powerbi/group/${idGroup}/reports/${idReporte}`
    }

    private urlObtenerToken = (idReporte: string, idBaseDatos: string) => {
        return `powerbi/token/${idReporte}/${idBaseDatos}`
    }

    private urlGetReport = 'powerbi/token'

    private urlActualizarBaseDatos = (idGroup: string, idBaseDatos: string) => {
        return `powerbi/group/${idGroup}/datasets/${idBaseDatos}/refresh`
    }

    private urlEstatusRefresh = (idGroup: string, idBaseDatos: string, idRequest: string) => {
        return `powerbi/group/${idGroup}/datasets/${idBaseDatos}/refresh/${idRequest}`
    }

    public async SetToken(loginResp: LoginResponse) {
        const token = new Token({
            accessToken: loginResp.AuthenticationResult.AccessToken,
            refreshToken: loginResp.AuthenticationResult.RefreshToken,
            idToken: loginResp.AuthenticationResult.IdToken,
            expiration: loginResp.AuthenticationResult.ExpiresIn
        })

        CRMApi.setToken(token)
    }

    public async GetToken() {

        return CRMApi.getToken()
    }

    public async ObtenerUrlReporte(idReporte: string): Promise<EmbedUrl | undefined> {
        const resp = await CRMApi.get<EmbedUrl>(this.urlObtenerUrlReporte(idReporte))

        return resp.data
    }

    public async ObtenerToken(request : RequestGetToken): Promise<Token> {
        const resp = await CRMApi.post<TokenPowerBi>(this.urlGetReport,request)

        const token = new Token({ accessToken: resp?.data.token, tokenId: resp?.data.tokenId, expiration: resp?.data.expiration })
        return token
    }

    public async ObtenerReporteDeGrupo(idGroup: string, idReporte: string): Promise<EmbedUrl> {
        const resp = await CRMApi.get<EmbedUrl>(this.urlObtenerReporteDeGrupo(idGroup, idReporte))

        return resp.data
    }

    public async ActualizarBasedeDatos(idGroup: string, idBaseDatos: string): Promise<string> {
        const resp = await CRMApi.get<string>(this.urlActualizarBaseDatos(idGroup, idBaseDatos))

        return resp.data
    }

    public async ObtenerEstatusRefesh(idGroup: string, idBaseDatos: string, idRequest: string): Promise<RefreshStatus> {
        const resp = await CRMApi.get<RefreshStatus>(this.urlEstatusRefresh(idGroup, idBaseDatos, idRequest))

        return resp.data
    }


    public async GetGeneralInfoReport(request:RequestGetToken) {
        
            const respReporteGrupo = await this.ObtenerReporteDeGrupo(PowerBIReports.GroupPowerBIEmbed, request.reports[0].id)
            const respObtenerToken = await this.ObtenerToken(request)

            return {
                token: respObtenerToken.accesstoken,
                EmbedUrl: respReporteGrupo.embedUrl,
                error: null
            }
    }

    public verifyLogin(): boolean {
        console.log("Iniciar verificación Token")
        const verify = CRMApi.verifyToken()
        return verify
    }


    public GetReportsByRol() {
        console.log("Iniciado tener reporte por rol")
        const rol: Roles = CRMApi.user["custom:role"] as Roles;
        const distribuidor = CRMApi.user["custom:accessLevel"];

        return ReportsByRol(rol,distribuidor)
    }

    public GetRole(){
        const rol: Roles = CRMApi.user["custom:role"] as Roles;

        return rol
    }

}

const CRMApi = HttpClientService.instance(process.env.REACT_APP_CRM_BASE_URL || "http://127.0.0.1:3000", false, process.env.REACT_APP_CRM_BASE_URL_LOGIN + "/refresh")

const crmService = new CRMService();

export default crmService;