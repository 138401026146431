import React from "react";
import { useState } from "react";
import compilarTextField from "../../../components/textField";
import FieldProps, { InputTypes } from "../../../components/textFieldProps";
import Button from "../../../components/button";
import { Navigate } from "react-router-dom";
import crmService from "../infrastructure/crm-service";
import crmLoginService from "../infrastructure/crm-login-service";
import { IconButton } from "@material-tailwind/react";
import { TbRefresh } from "react-icons/tb";



export default function Login() {
  const [loginData, setLoginData] = useState({ username: "", password: "" });
  const [logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onError, setOnError] = useState(false);

  const handleSendUserInfo = async (e: any) => {

    try {

      setLoading(true)
      const login = await crmLoginService.Login(loginData.username, loginData.password)

      const data = login.data

      crmService.SetToken(data)

      setLogged(true)

    } catch (e) {
      setLoading(false)
      setOnError(true)

    }
  };

  const handleKeyPress = async (e: any) => {
    if (e.key === 'Enter') {
      await handleSendUserInfo(e)
    }
  }



  const userProps: FieldProps = {
    model: loginData,
    required: true,
    setModel: setLoginData,
    campoId: "username",
    placeholder: "Usuario",
    type: InputTypes.Text,
  }

  const passwordProps: FieldProps = {
    model: loginData,
    required: true,
    setModel: setLoginData,
    campoId: "password",
    placeholder: "Contraseña",
    type: InputTypes.Password,
    onKeyUp: handleKeyPress
  }

  return (
    <>
      <div className="grid md:grid-cols-2 place-items-center w-full h-full min-h-screen">
        <div className="bg-purple w-full h-full min-h-screen hidden md:grid  md:place-items-center">
          <img src="/logos/logo_blue.png" className="h-44" alt="" />
        </div>
        <div className="space-y-5">
          <img src="/logos/logo_blue.png" className="h-32 m-auto md:hidden" alt="" />
          <div className="md:h-32 space-y-4">
            <h3 className="text-2xl font-bold ">¡Bienvenido de nuevo!</h3>
            <h3 className="font-semibold text-gray-500">Inicio de sesión</h3>
          </div>

          <form className="space-y-5">
            {compilarTextField(userProps)}
            {compilarTextField(passwordProps)}
            {onError && <p className="text-red-400"> Verifica tus credenciales.</p>}
            <div className={"flex"}>
              <Button type="button" onClick={handleSendUserInfo} className="w-full h-12 bg-purple hover:bg-deep-purple-700 text-center text-white rounded-md font-bold flex justify-center items-center">Entrar</Button>
              {loading && <IconButton  className={"animate-spin absolute right-40"} children={<TbRefresh size={32} color="black"  />} />}
            </div>
          </form>
          {logged && <Navigate to={"/reportes"}></Navigate>}
        </div>
      </div>
    </>
  );
}
