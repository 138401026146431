import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Factura from "../components/Factura";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

export default function Facturas() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { state } = useLocation();

  const facturas = state;

  const handdleSelectFactura = (fact) => {
    navigate(`/pagos/${id}/${fact["numProduct"]}`, { state: fact });
  };

  return (
    <>
      <NavBar bgColor="bg-purple" navigate back/>
      <div className="h-full min-h-[80vh] w-screen py-10">
        <div className="text-center text-lg text-purple font-semibold">
          Facturas pendientes
        </div>
        <div className="text-center text-base text-gray-600 pt-4 px-10">
          Aquí encontrará sus facturas pendientes, seleccione una para continuar
          con el pago.
        </div>
        <div className="md:flex flex-row justify-center w-screen overflow-x-hidden flex-wrap">
          {facturas.map((f) => {
            return (
              <>
                <div
                  onClick={() => {
                    handdleSelectFactura(f);
                  }}
                >
                  <Factura factura={f}></Factura>
                </div>
              </>
            );
          })}
        </div>
        <div className="text-center text-purple font-semibold py-5">
            <a
              href="https://wa.link/2ilmio"
              target={"_blank"}
              rel="noreferrer"
              className=""
            >
              ¿Necesitas Soporte? Comunicate con nosotros
            </a>
          </div>
      </div>
      
      <Footer/>
    </>
  );
}
