class LocalStorageService {
    

    save(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    get<T>(key: string) {
        return typeof window !== 'undefined' ? localStorage.getItem(key) : null;
    }

    delete(key: string) {
        localStorage.removeItem(key);
    }
}

const localStorageService = new LocalStorageService();
export default localStorageService; 