import * as React from 'react';
import SelectField from "../components/select-field";
import ColumnsCredito from "./columns-credito";
import ColumnsRenting from "./columns-renting";
import { SimularProducto } from "../entities/simular-productos";
import FieldProps, { InputTypes } from '../components/text-field-props';
import compilarTextField from '../components/text-field';
import Tabla from '../components/tabla';
import simulador from '../infraestructure/simulacion-simulacion';
import WavesDivider from "../../../utils/WavesDivider";

interface SimulacionProductos {
    simulacion : SimularProducto
    setSimulacion : Function
    resetSimulacion : Function
}

export default function Simulador () {

    const [simulacion, setSimulacion] = React.useState<SimularProducto>(new SimularProducto())
    const [amortizacion, setAmortizacion] = React.useState()
    const [reset,setReset] = React.useState<Boolean>(false)

    const resetSimulacion = () => {
        setReset(false)
        return setSimulacion
    }

    const columnsCredito = ColumnsCredito();
    const columnsRenting = ColumnsRenting();

    const submitHandler = (e : any) => {
        e.preventDefault();
        return true;
    }

    const simularProducto = async () => {
        
        const resp = await simulador.simulador(SimularProducto.compilarDTO(simulacion));
        setReset(true);
        setAmortizacion(resp);
    }

    return (
        <>
        
            <div className=' bg-[#390094] min-h-screen relative block absolute'>  
                <div>
                    <img
                    src="logos/logo_white_finmaq.png"
                    className='w-36 p-2 absolute right-0 '
                    alt=""
                    >
                    </img>
                </div>    
                <div className="w-full absolute bottom-0">
                <WavesDivider />
            </div>
            
            <div>          
                <div className='flex relative'>
                    <h3 className="text-3xl font-bold text-white mt-16 ml-44">Simular Producto</h3>
                </div>
                <div>
                    <section className="text-lg font-normal ml-44 text-white mt-8 ">Aquí podras realizar la simulacion de un producto: </section>
                    <div className='grid place-content-center mt-4'>
                        <img
                            src="resources/triangles/white_triangles_left.png"
                            className="absolute top-0 -left-50 w-32 md:w-40 lg:w-52 z-10"
                            alt=""
                        />
                        <img
                            src="resources/triangles/white_triangles_right.png"
                            className="absolute top-20 right-0 w-32 md:w-40 lg:w-52 "
                            alt=""
                        />
                        <form className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4  z-10 gap-10' onSubmit={submitHandler}>
                            <FormularioSimulacion simulacion={simulacion} setSimulacion={setSimulacion} resetSimulacion={resetSimulacion}/>
                            <div>
                                <button onClick={simularProducto} type="submit" className='bg-[#7ebfdf] w-52 p-3 mt-4 rounded-md text-white'>Simular</button>
                            </div>
                        </form>
                        {amortizacion && reset && <Tabla className={"z-10 bg-white rounded.lg"} columns={simulacion.tipoID == 3 ? columnsRenting : columnsCredito} data={amortizacion}  ></Tabla> }
                    </div>
                </div>
                
            </div>
            </div>
            
        </>
    )
}

function FormularioSimulacion ({simulacion, setSimulacion, resetSimulacion}: SimulacionProductos) {
    return <>
        <Tipo simulacion={simulacion} setSimulacion={setSimulacion} resetSimulacion={resetSimulacion}/>
        <Plazo simulacion={simulacion} setSimulacion={setSimulacion} resetSimulacion={resetSimulacion}/>
        <CiclosFacturacion simulacion={simulacion} setSimulacion={setSimulacion} resetSimulacion={resetSimulacion}/>
        <Tasa simulacion={simulacion} setSimulacion={setSimulacion} resetSimulacion={resetSimulacion}/>
        <FechaDesembolso simulacion={simulacion} setSimulacion={setSimulacion} resetSimulacion={resetSimulacion}/>
        <Monto simulacion={simulacion} setSimulacion={setSimulacion} resetSimulacion={resetSimulacion}/>
        <ValorDeLaMaquina simulacion={simulacion} setSimulacion={setSimulacion} resetSimulacion={resetSimulacion}/>
    </>
}

const Tipo =  ({simulacion, setSimulacion, resetSimulacion}: SimulacionProductos) => {
    return <SelectField
    model={simulacion}
    setModel={setSimulacion}
    campoId="tipoID"
    type={InputTypes.Number}
    placeholder="Tipo producto"
    onReset={resetSimulacion}
    >
        <option value={"0"}>Tipo producto</option>
        <option value={1}>Crédito cuota fija</option>
        <option value={2}>Crédito rotativo</option>
        <option value={3}>Renting</option>
    </SelectField>
    
}

const Plazo = ({simulacion, setSimulacion, resetSimulacion}: SimulacionProductos) => {
    return <SelectField
    model={simulacion}
    setModel={setSimulacion}
    campoId='plazoID'
    type={InputTypes.Number}
    placeholder="Plazo"
    onReset={resetSimulacion}
    >
        <option value={"0"}>Plazo</option>
        <option value={2}>12 meses</option>
        <option value={3}>24 meses</option>
        <option value={4}>36 meses</option>
        <option value={5}>48 meses</option>
    </SelectField>
}

const CiclosFacturacion = ({simulacion, setSimulacion, resetSimulacion}: SimulacionProductos) => {
    return  <SelectField
    model={simulacion}
    setModel={setSimulacion}
    campoId='ciclosFacturacionID'
    type={InputTypes.Number}
    placeholder='Ciclo Facturación'
    onReset={resetSimulacion}
    >
        <option value={"0"}>Ciclo de Facturación</option>
        <option value={1}>15</option>
        <option value={2}>30</option>
    </SelectField>
}

const Tasa = ({simulacion, setSimulacion} : SimulacionProductos) => {
    const  fieldProps: FieldProps = {
        model: simulacion,
        setModel: setSimulacion,
        campoId: "tasa",
        label: "Tasa",
        placeholder: "Tasa",
        type: InputTypes.Percent
    }
    return compilarTextField(fieldProps)
}

const FechaDesembolso = ({simulacion, setSimulacion} : SimulacionProductos) => {
    const fieldProps: FieldProps = {
        model: simulacion,
        setModel: setSimulacion,
        campoId: "fechaDesembolso",
        label: "Fecha Desembolso",
        placeholder: "Fecha Desembolso",
        type: InputTypes.Date
    }
    return compilarTextField(fieldProps)
}

const Monto = ({simulacion, setSimulacion}: SimulacionProductos) => {
    const fieldProps: FieldProps = {
        model: simulacion,
        setModel: setSimulacion,
        campoId: "monto",
        label: "Monto",
        placeholder: "Monto",
        type: InputTypes.Number
    }
    return compilarTextField(fieldProps)
}

const ValorDeLaMaquina = ({simulacion, setSimulacion}: SimulacionProductos) => {
    const fieldProps : FieldProps = {
        model: simulacion,
        setModel: setSimulacion,
        campoId: "valorDeLaMaquina",
        label: "Valor de la maquina",
        placeholder: "Valor de la maquina",
        type: InputTypes.Number
    }
    return compilarTextField(fieldProps)
}
