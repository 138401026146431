import React from "react";
import { Link } from "react-router-dom";
import { Link as Goto } from "react-scroll";
import WavesDivider from "../utils/WavesDivider";
import Footer from "../components/Footer";
import CardC from "../components/CardC";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Header from "../components/NavBar";

//TODO: Poner Tratamiento de datos personales. Hay algo en TyC de la app?
//TODO: Cambiar descripción en primera vista. Whatsapp.
//TODO: Quienes somoss cambio de fondo con patrón. Cambiar texto. Whatsapp. Patron Productos de crédito.
//TODO: Unir alianza comerciales con nuestros aliados.
//TODO: Tres sectores principales: Maquinaria amarilla, Sector Salud, Industrial (Barra de sectores) Distribuidores por sector. Descripción por sección. Whatsapp.
//TODO: Quitar contactenos. Notas de voz (Mirar que tan posible es poner notas de voz) para testimonios.
//TODO: Burbuja de Linkdin.

export default function Main() {
  gsap.registerPlugin(ScrollTrigger);

  const ref = useRef();

  // useEffect(() => {
  //   const element = ref.current;
  //   gsap.to(".link ", {
  //     color: "#ffffff",
  //     fill: "#ffffff",
  //     scrollTrigger: {
  //       trigger: ".home",
  //       start: "top 100",
  //       toggleActions:"play complete reverse reset",
  //       end: "bottom center",

  //     },
  //   });
  //   gsap.to(".link", {
  //     color: "#390094",
  //     fill: "#390094",
  //     scrollTrigger: {
  //       trigger: ".porpuse",
  //       start: "top 200",
  //       end: "bottom center",
  //       toggleActions:"play complete reverse reset",

  //     },
  //   });
  //   gsap.to(".link", {
  //     color: "#390094",
  //     fill: "#390094",
  //     scrollTrigger: {
  //       trigger: ".products",
  //       start: "top 200",
  //       end: "bottom center",
  //       toggleActions:"play complete reverse reset",

  //     },
  //   });
  //   gsap.to(".link", {
  //     color: "#ffffff",
  //     fill: "#ffffff",
  //     scrollTrigger: {
  //       trigger: ".financial-solutions",
  //       start: "top 200",
  //       end: "bottom center",
  //       toggleActions:"play complete reverse reset",

  //     },
  //   });
  //   gsap.to(".link", {
  //     color: "#ffffff",
  //     fill: "#ffffff",
  //     scrollTrigger: {
  //       trigger: ".partners",
  //       start: "top 200",
  //       end: "bottom center",
  //       toggleActions:"play complete reverse reset",

  //     },
  //   });
  //   gsap.to(".link", {
  //     color: "#390094",
  //     fill: "#390094",
  //     scrollTrigger: {
  //       trigger: ".partners-white",
  //       start: "top 200",
  //       end: "bottom center",
  //       toggleActions:"play complete reverse reset",

  //     },
  //   });
  //   gsap.to(".link", {
  //     color: "#ffffff",
  //     fill: "#ffffff",
  //     scrollTrigger: {
  //       trigger: ".testimonios",
  //       start: "top 200",
  //       end: "bottom center",
  //       toggleActions:"play complete reverse reset",

  //     },
  //   });

  //   gsap.to(".link", {
  //     color: "#390094",
  //     fill: "#390094",
  //     scrollTrigger: {
  //       trigger: ".contactenos",
  //       start: "top 200",
  //       end: "bottom center",
  //       toggleActions:"play complete reverse reset",

  //     },
  //   });
  // });

  return (
    <>
      <div className="App relative" ref={ref}>
        <PayBar />
        <FloatingWhatsApp
          phoneNumber="573205621643"
          avatar="logos/logo.png"
          onSubmit={(v) => {
            console.log(v);
          }}
          accountName="FinMaq"
          chatMessage="Hola, ¿cómo estás, en qué podemos ayudarte?"
          placeholder="Escribe aquí tu consulta"
        />
        {/* //* Las secciones estan conformadas así:
             //* home
             //* who-we-are
             //* financial-solutions
             //* partners
             //* contact
          */}

        <Header links fixed />

        <Home />
        <Purpose />
        <WhoWeAre />
        {/* <FinancialSolutions /> */}
        <Products />
        <ComercialPartners />
        <CasesOfSuccess />
        {/* <ContactUs /> */}
        <SocialMedia />
        <Footer links />
      </div>
    </>
  );
}

function ContactUs() {
  return (
    <div className=" bg-white md:grid md:grid-cols-2 p-10 md:py-32 space-y-5 contactenos">
      <div className="text-purple mx-auto my-auto text-left flex-col flex space-y-5">
        <h1 className=" text-4xl md:text-6xl">Contáctenos</h1>
        <p>Y le ayudaremos a tomar la mejor decisión</p>
        <a href="https://api.whatsapp.com/send?phone=573205621643&text=Le%20damos%20la%20bienvenida%20a%20FSM.%20">
          <button className=" flex space-x-3  place-self-center md:text-2xl  w-max bg-purple hover:bg-slate-300 px-4 py-2 md:px-8 md:py-4 text-white font-medium rounded-xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="38"
              fill="currentColor"
              class="bi bi-whatsapp"
              viewBox="0 0 16 16"
            >
              <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
            </svg>
            <div>SOLICITE UNA ASESORÍA</div>
          </button>
        </a>
      </div>
      <div className="grid place-items-center">
        <img
          src="resources/sections/service.png"
          className="w-64 md:w-96"
          alt=""
        />
      </div>
    </div>
  );
}

function CasesOfSuccess() {
  return (
    <div className=" w-full h-full products bg-purple testimonios">
      <div className="pt-10  text-2xl md:text-4xl font-medium text-white md:pt-20 bg-img">
        Testimonios
      </div>
      <div className="bg-purple flex flex-col md:flex-row p-10  justify-evenly products ">
        <CardC
          title={"Clínica Oftalmológica del Quindio"}
          img={"alianzas/ClinicaQuindio.png"}
          description={
            "Los sentimos como un gran aliado, nos permitió renovar los equipos de quirófano en la parte de anestesia."
          }
        ></CardC>
        <CardC
          title={"Korial Group"}
          img={"alianzas/logo-korial.png"}
          description={
            "Logramos adquirir un segundo ecógrafo, Finmaq confió en Korial Group y nos otorgan y nos apoyan con el crédito para adquirir la máquina."
          }
        ></CardC>
        <CardC
          title={"Imocom"}
          img={"alianzas/Imocom.png"}
          description={
            "Finmaq ha entendido el sector en el que nosotros trabajamos, permitiéndonos aumentar las ventas por medio de acceso a financiación."
          }
        ></CardC>
      </div>
    </div>
  );
}

function ComercialPartners() {
  return (
    <>
      <div className="relative">
        <div className=" p-10 md:p-40 bg-turquese grid md:grid-cols-2 partners font-poppins">
          <div className="w-52 md:w-96 m-auto">
            <img
              src="resources/sections/partners.png"
              className="object-scale-down"
              alt=""
            />
          </div>
          <div className="text-white text-center m-auto font-poppins ">
            <h4 className="text-3xl md:text-5xl md:text-left py-10">
              Alianzas <br /> Comerciales
            </h4>
            <h3 className="text-base md:text-xl font-extralight m-auto">
              Juntamos Fuerzas para que su negocio crezca.
            </h3>
          </div>
        </div>
        <div className="w-full absolute bottom-0">
          <WavesDivider />
        </div>
      </div>

      <div className="our-partners relative partners-white partners-white">
        <h2 className="text-3xl pt-10 text-purple md:text-left md:px-32 md:text-5xl">
          Nuestros Aliados
        </h2>
        <div className="p-10 md:m-5 bg-white grid md:grid-cols-3 partners font-poppins">
          <div className="grid grid-cols-3 gap-5 grid-rows-4 place-items-center col-span-2">
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://www.ayj.com.co/"
            >
              <img src="alianzas/A&JMaquinaria.png" alt="" />
            </a>
            <a target={"_blank"} rel="noreferrer" href="https://andrec.com.co/">
              <img src="alianzas/Andrec.png" alt="" />
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://biotronitech.com.co/"
            >
              <img src="alianzas/Biotronitech.png" alt="" />
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://www.eurodent.com.co/"
            >
              <img src="alianzas/EuroDent.jpeg" alt="" />
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://www.gehealthcare.com/"
            >
              <img src="alianzas/GE-Healthcare.png" alt="" />
            </a>
            <a target={"_blank"} rel="noreferrer" href="https://imocom.com.co/">
              <img src="alianzas/Imocom.png" alt="" />
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://www.lminstruments.com.co/"
            >
              <img src="alianzas/LmInstruments.png" alt="" />
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://www.navitrans.com.co/"
            >
              <img src="alianzas/Navitrans.png" alt="" />
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://partequipos.com/"
            >
              <img src="alianzas/PartEquipos.png" alt="" />
            </a>
            <a target={"_blank"} rel="noreferrer" href="https://progyne.com/">
              <img src="alianzas/Progyne.png" alt="" />
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://www.promedco.com/"
            >
              <img src="alianzas/Promedco.png" alt="" />
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://www.signproducts.com.co/"
            >
              <img src="alianzas/SignProducts.png" alt="" />
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://www.zaneolatam.com/"
            >
              <img src="alianzas/Zaneo.png" alt="" />
            </a>
          </div>
          <div className="relative bg-purple rounded-3xl hidden md:flex flex-col m-5 text-white shadow-xl -top-52">
            <h4 className="text-4xl py-10 ">Sectores</h4>
            <h2 className="font-extralight px-10">
              Trabajamos con <b>diferentes sectores </b>de la economia para
              brindarle el <b>mejor financiamiento</b> en su area de interes
            </h2>
            <div className="flex flex-col h-full text-xl justify-evenly">
              <h2 className="bg-blue rounded-lg mx-12 py-2">
                {" "}
                Maquinaria amarilla
              </h2>
              <h2 className="bg-blue rounded-lg mx-12 py-2">
                {" "}
                Equipos Médicos
              </h2>
              <h2 className="bg-blue rounded-lg mx-12 py-2"> Transporte </h2>
              <h2 className="bg-blue rounded-lg mx-12 py-2">
                {" "}
                Industria Pesada{" "}
              </h2>
              <h2 className="bg-blue rounded-lg mx-12 py-2">
                {" "}
                Salud y Belleza{" "}
              </h2>
              <h2 className="bg-blue rounded-lg mx-12 py-2"> Construcción </h2>
              <h2 className="bg-blue rounded-lg mx-12 py-2">
                {" "}
                Diseño Gráfico | Imprenta{" "}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Products() {
  return (
    <div className=" w-full h-full products">
      <div className="pt-10  text-2xl md:text-4xl font-medium text-purple md:pt-20 bg-img">
        Productos de Credito
      </div>
      <div className="bg-white flex flex-col md:flex-row m-6 md:m-16 justify-evenly products ">
        <CardC
          title={"Crédito"}
          img={"resources/products/Data.png"}
          description={
            "Financiación tradicional de activos productivos para los sectores de la economía."
          }
        ></CardC>
        <CardC
          title={"Renting"}
          img={"resources/products/Pig.png"}
          description={
            "Contrato que permite al arrendador utilizar los equipos durante un plazo definido sin necesidad de cuota inicial."
          }
        ></CardC>
        <CardC
          title={"Crédito Rotativo"}
          img={"resources/products/Phone.png"}
          description={
            "Crédito rotativo para la financiación de mantenimientos y/o consumibles para el buen funcionamiento de la maquinaria."
          }
        ></CardC>
      </div>
      <div className="w-full grid place-content-center my-10">
        <a href="https://api.whatsapp.com/send?phone=573205621643&text=Le%20damos%20la%20bienvenida%20a%20FSM.%20">
          <button className=" flex space-x-3 place-self-center  hover:scale-110 ease-in duration-100 shadow-lg w-max bg-[#23D366] hover:bg-slate-300 px-6 py-4 text-white font-medium rounded-xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-whatsapp"
              viewBox="0 0 16 16"
            >
              <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
            </svg>
            <div>SOLICITE UNA ASESORIA</div>
          </button>
        </a>
      </div>
    </div>
  );
}

function FinancialSolutions() {
  return (
    <div className=" bg-purple md:grid md:grid-cols-2 p-10 md:py-32 space-y-5 financial-solutions">
      <div className="text-white mx-auto my-auto text-left flex-col flex space-y-5">
        <h1 className="text-4xl">
          Soluciones <br /> Financieras
        </h1>
        <p>Alternativas que se adaptan a tu necesidad</p>
        <a href="https://api.whatsapp.com/send?phone=573205621643&text=Le%20damos%20la%20bienvenida%20a%20FSM.%20">
          <button className=" flex space-x-3  place-self-center  w-max bg-white hover:bg-slate-300 px-4 py-2 text-purple font-medium rounded-xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-whatsapp"
              viewBox="0 0 16 16"
            >
              <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
            </svg>
            <div>SOLICITE UNA ASESORIA</div>
          </button>
        </a>
      </div>
      <div className="grid place-items-center">
        <img
          src="resources/sections/money_chip.png"
          className="w-64 md:w-96"
          alt=""
        />
      </div>
    </div>
  );
}

function WhoWeAre() {
  return (
    <div className="grid md:flex-row-reverse products  py-10 md:py-40 md:grid-cols-2 lg:grid-cols-3 bg-blue who-we-are">
      <div className="md:my-auto md:mx-auto">
        <p className="text-2xl py-10 text-purple font-medium md:text-4xl">
          ¿Quiénes somos?
        </p>
        <h1 className="text-white font-light px-5 md:max-h-24 text-sm md:text-lg ">
          Somos una empresa innovadora en la financiación de activos productivos
          a través de productos de crédito.
        </h1>
      </div>
      <img
        src="resources/sections/robotFinMaq.png"
        className=" mx-auto md:min-h-92 md:min-w-92 md:my-auto"
        alt=""
      />
      <p className="md:my-auto text-white  md:p-10 text-medium md:text-base hidden lg:block">
        Fue fundada en 2014 bajo el nombre comercial de FSM, con el objetivo de
        financiar a emprendedores y clientes de nuestros DISTRIBUIDORES ALIADOS,
        donde nuestra oferta de valor está fundamentada en el conocimiento del
        cliente y el sector de nuestros aliados lo cual nos permite entender
        mejor el riesgo y facilita el proceso de estudio de crédito. Somos un
        equipo de alta experiencia en el manejo del riesgo y en paralelo a la
        Ley de Garantías mobiliarias impulsaremos el crecimiento empresarial y
        la inclusión financiera en nuestro país.
      </p>
    </div>
  );
}

function SocialMedia() {
  return (
    <div className="h-16 bg-blue w-full flex justify-center space-x-10 items-center">
      <a href="https://www.linkedin.com/company/finmaq/about/">
        <img src="resources/socialmedia/linkedin.png" alt="" />
      </a>
      <a href="https://www.crunchbase.com/organization/fsm-98aa">
        <img src="resources/socialmedia/crunchbase.png" alt="" />
      </a>
    </div>
  );
}

function Purpose() {
  return (
    <div className="bg-white md:grid-cols-2 md:grid p-10 relative porpuse">
      <div className="flex absolute -top-40 w-full justify-around">
        <div className="hidden md:block w-[10vh] rotate-180">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.11 20.56">
            <defs>
              <style>{".cls-1{fill:#3b2680;}"}</style>
            </defs>
            <g id="Capa_2" data-name="Capa 2">
              <g id="Capa_1-2" data-name="Capa 1">
                <path
                  class="cls-1"
                  d="M21.83,16.16a1.5,1.5,0,0,1-1.06,2.37l-9.56,1-9.55,1a1.5,1.5,0,0,1-1.53-2.1L4,9.67,7.94.89A1.5,1.5,0,0,1,10.53.62l5.65,7.77Z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="hidden md:block md:absolute md:bottom-20 md:w-[12vh] rotate-3">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.81 39.47">
            <defs>
              <style>{".cls-1{fill:#51c1e1;}"}</style>
            </defs>
            <g id="Capa_2" data-name="Capa 2">
              <g id="Capa_1-2" data-name="Capa 1">
                <path
                  class="cls-1"
                  d="M37.72,8.6A2.76,2.76,0,0,1,39,13.19L26.78,25.91,14.52,38.63a2.76,2.76,0,0,1-4.64-1.15L5,20.5.11,3.52A2.76,2.76,0,0,1,3.42.08L20.57,4.34Z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className=" hidden md:block w-[12vh] rotate-45">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.81 39.47">
            <defs>
              <style>{".cls-1{fill:#51c1e1;}"}</style>
            </defs>
            <g id="Capa_2" data-name="Capa 2">
              <g id="Capa_1-2" data-name="Capa 1">
                <path
                  class="cls-1"
                  d="M37.72,8.6A2.76,2.76,0,0,1,39,13.19L26.78,25.91,14.52,38.63a2.76,2.76,0,0,1-4.64-1.15L5,20.5.11,3.52A2.76,2.76,0,0,1,3.42.08L20.57,4.34Z"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div className="text-purple">
        <div className="font-normal text-2xl p-5 md:text-start md:max-w-min md:text-6xl md:mx-20 ">
          Nuestro propósito
        </div>
        <h4 className="font-light p-5 text-sm md:text-justify md:text-lg md:mx-20">
          Es generar impacto social positivo a través inclusión financiera. Le
          préstamos a empresa y personas natural sin requerimiento mínimo.
          <br /> <br />
          Creamos innovadoras y agiles{" "}
          <b className="font-bold">herramientas de financiación</b> asegurando
          el <b className="font-bold">crecimiento empresarial</b> de las Pymes y
          personas naturales en Colombia, esto basándonos en nuestras alianzas
          comerciales entregando el mejor servicio a nuestros clientes para la
          compra de sus activos productivos.
        </h4>
      </div>
      <div className="grid place-items-center relative">
        <img
          className="w-52 md:w-96 md:absolute "
          src="resources/triangles/triangles.png"
          alt=""
        />
        <img
          src="resources/sections/world.png"
          className="absolute w-36 md:w-64 md:absolute"
          alt=""
        />
      </div>
    </div>
  );
}

function Home() {
  return (
    <div className="home">
      <img
        src="resources/triangles/white_triangles_left.png"
        className="absolute top-0 -left-50 w-32 md:w-40 lg:w-52"
        alt=""
      />
      <img
        src="resources/triangles/white_triangles_right.png"
        className="absolute top-20 right-0 w-32 md:w-40 lg:w-52"
        alt=""
      />
      <div className="bg-gradient-to-b from-purple to-purple/80 home">
        <div className="md:grid md:grid-cols-2">
          <div className="md:grid md:place-items-center  h-[80vh] md:place-content-center w-screen md:w-auto flex justify-center items-center">
            <svg
              className="scale-50 w-[500px]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 289.54 326.13"
            >
              <defs>
                <style>"{".cls-1{fill:#51c1e1;}"}"</style>
              </defs>
              <g id="Capa_2" data-name="Capa 2">
                <g id="Layer_1" data-name="Layer 1">
                  <path
                    class="cls-1"
                    d="M255.79,136.48l21.74,17.33a7.48,7.48,0,0,0,7.77,1.05,7,7,0,0,0,4.24-6.38V20.79A20.5,20.5,0,0,0,276.94,1.9a21.88,21.88,0,0,0-23,3.25L156.13,92.29a20.26,20.26,0,0,0-.07,31.2l84.14,66.66-95.43,89.9-95.42-89.9,84.13-66.66a20.26,20.26,0,0,0-.07-31.2L35.64,5.15a21.88,21.88,0,0,0-23-3.25A20.5,20.5,0,0,0,0,20.79V148.48a7,7,0,0,0,4.24,6.38A7.48,7.48,0,0,0,12,153.81l21.74-17.33a7,7,0,0,0,2.54-5.33V59.92l55,48.08L8,173.91a20.26,20.26,0,0,0-.81,30.41L130.25,320.25a21.67,21.67,0,0,0,14.52,5.88h0a21.67,21.67,0,0,0,14.52-5.88L282.35,204.32a20.26,20.26,0,0,0-.81-30.41L198.26,108l55-48.08v71.23a7,7,0,0,0,2.54,5.33"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="h-[20vh] md:h-auto md:grid   md:place-content-start md:my-auto">
            <div className="py-5 text-white">
              <h1 className="text-2xl md:text-4xl font-medium">
                Financiamos Su Máquina
              </h1>
              <h3 className="text-sm md:text-lg font-light">
                Rápido, seguro y sin tanto papeleo.
              </h3>
            </div>

            <button className="hidden place-self-center  w-max md:block bg-white hover:bg-slate-300 px-3 py-1 text-purple font-medium rounded-xl">
              <a href="https://api.whatsapp.com/send?phone=573205621643&text=Le%20damos%20la%20bienvenida%20a%20FSM.%20">
                HAGA CLIC AQUÍ
              </a>
            </button>
          </div>
        </div>
        <WavesDivider />
      </div>
      <div className="bg-white py-5">
        <button className="bg-purple px-3 py-1 text-white font-medium rounded-xl md:hidden">
          <a href="https://api.whatsapp.com/send?phone=573205621643&text=Le%20damos%20la%20bienvenida%20a%20FSM.%20">
            HAGA CLIC AQUÍ
          </a>
        </button>
      </div>
    </div>
  );
}

function PayBar() {
  return (
    <div className="h-12 md:h-14 bg-blue w-full flex justify-center md:justify-between ">
      <div className="hidden md:flex">
        <h4 className="text-white text-sm font-medium place-self-center"></h4>
        <Link
          to="/login"
          className="mx-5 place-self-center w-max text-sm bg-purple hover:bg-purple/80 px-6 py-2 z-10 text-white font-medium rounded-xl"
        >
          <button className="">Login CRM</button>
        </Link>
      </div>
      <div className="hidden md:flex mx-20">
        <h4 className="text-white text-xl font-medium place-self-center ">
          Realiza tus pagos
        </h4>
        <Link
          to="/pagos"
          className="mx-10 place-self-center w-max  bg-purple hover:bg-purple/80 px-6 py-2 text-white font-medium rounded-xl"
        >
          <button className="">AQUÍ</button>
        </Link>
      </div>
      {
        // Botones para celular.
      }
      <div className="flex md:hidden">
        <Link
          to="/pagos"
          className="mx-10 place-self-center w-max  bg-purple hover:bg-purple/80 px-6 py-2 text-white font-medium rounded-xl"
        >
          <button className="">Pagos</button>
        </Link>
      </div>
    </div>
  );
}
