import React, { KeyboardEventHandler } from "react";
import { MouseEventHandler } from "react"
type ButtonType = 'button' | 'submit' | 'reset';

class ButtonProps  {
    className?: string
    children?: React.ReactNode
    type?:ButtonType
    label?: string
    onClick?: MouseEventHandler<HTMLButtonElement>
    onKeyUp?: KeyboardEventHandler<HTMLButtonElement>
}
        
export default function Button(props: ButtonProps) {
    return (
        <button 
        className={`w-72 h-12 px-4 py-2 bg-purple hover:bg-deep-purple-700 text-center text-white rounded-md font-bold flex justify-center items-center ${props.className}`}
        color="deep-purple"
        onClick={props.onClick}
        onKeyUp={props.onKeyUp}
        type={props.type ?? 'button'}>
            {props.children}
        </button>
    )
}