
import { useEffect, useState } from 'react';
import React from 'react'
import { Report } from 'powerbi-report-component';



interface Access {
    token: string,
    embedUrl: string
}


export default function PowerBiReport({ reportid, token, embedUrl, pageName}: any) {
    const [access, setAccess] = useState<Access>()



    return <div className={"flex items-center justify-center w-screen h-screen"}>
        {<div id='reportCSS'>
            <Report
                embedId={reportid}
                permissions='Read'
                reportMode="View"
                tokenType="Embed"
                embedUrl={embedUrl}
                accessToken={token}
                pageName={pageName}
                
                extraSettings={{
                    
                    filterPaneEnabled: false, //true
                    navContentPaneEnabled: true, //true
                    hideErrors: true // Use this *only* when you want to override error experience i.e, use onError
                    // ... more custom settings
                }}
                style={{ height: "100vh", width: "100vw", display: "none" }}
                onLoad={(reportBI: any) => {
                    reportBI.element.style.display = "flex"
                }}
            />
        </div>}
       
    </div>
}
