
import React from "react";
import FieldProps, { InputTypes } from "./text-field-props"
import CurrencyFormat from "react-currency-format";


const compilarTextField = ({model, setModel, label, placeholder, type, required, campoId, onBlur}: FieldProps) => {
    return (
        <div>
            <div className="text-sm m-0 text-[#7ebfdf] font-semibold w-52"> {placeholder}
                {(type === InputTypes.Number || type === InputTypes.Percent)?
                <FormatInput campoId={campoId} model={model} setModel={setModel} placeholder={placeholder} type={type} required={required} onBlur={onBlur}/> :
                <BasicInput campoId={campoId} model={model} setModel={setModel} placeholder={placeholder} type={type} required={required} onBlur={onBlur}/>}
            </div>
        </div>
    );
}

const BasicInput = ({model, setModel, label, placeholder, type, required, campoId, onBlur}: FieldProps) => {
    return(
        <input
        required={required}
        type={type}
        className="w-52 text-blue-gray-700 font-sans font-normal px-3 py-2.5 rounded-[7px] border   focus:outline-none focus:border-2 border-[#7ebfdf] focus:border-[#7ebfdf]"
        placeholder={placeholder}
        value={InputTypes.Date === type? model[campoId]?.toISOString().substring(0,10):model[campoId]}
        onChange={(e) => {
            const esNumero = (type === InputTypes.Number);
            const esFecha = (type === InputTypes.Date);
            let valor:any = esNumero ? parseFloat(e.target.value) : e.target.value;
            valor = esFecha? new Date(valor):valor;
            model[campoId] = valor;
            setModel({ ...model });
          }}
          onBlur={onBlur}
        />
    )
} 

const FormatInput = ({ model, setModel, campoId, placeholder, type, required, onBlur }: FieldProps) => {
    return <CurrencyFormat
      prefix={type === InputTypes.Money ? "$" : ""}
      suffix={type === InputTypes.Percent ? "%" : ""}
      // format={type === InputTypes.Percent ? "#.###%" : undefined}
      value={model[campoId]}
      onValueChange={(values : any) => {
        const { formattedValue, value, floatValue } = values;
        model[campoId] = value
        setModel({ ...model });
      }}
      className='w-52 text-blue-gray-700 font-sans font-normal px-3 py-2.5 rounded-[7px] border focus:outline-none focus:border-2 border-[#7ebfdf] focus:border-[#7ebfdf]'
      required={required}
      placeholder={placeholder}
      color="deep-purple"
      thousandSeparator={(type === InputTypes.Money) ? true : false}
      max={type === InputTypes.Percent ? 100 : null}
    />
  }
  
  export default compilarTextField;