import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  useSearchParams,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import api from "../api/api";

export default function PayConfirm() {
  const [searchParams] = useSearchParams();
  const patrimony = searchParams.get("patrimony");
  const { ref } = useParams();
  const [state, setState] = useState();
  const [razon, setRazon] = useState();
  const [email, setEmail] = useState();
  const [data, setData] = useState();
  const navigator = useNavigate();

  useEffect(() => {
    console.log(ref, patrimony);
    getPayInfo();
  }, []);

  const handleOnClick = () => {
    navigator("/comprobante", { state: data });
  };

  const getPayInfo = () => {
    console.log(patrimony);
    api
      .get(`/transactions/${ref}?patrimony=${patrimony}`)
      .then((resp) => {
        console.log(resp);
        const data = resp["data"]["data"];
        const razon = data["respuesta"];
        const estado = data["estado"];
        const correo = data["email"];
        console.log(data);
        console.log(estado);

        setData(data);
        setState(estado);
        setRazon(razon);
        setEmail(correo);
        if (estado == "Pendiente") {
          getPayInfo();
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  return (
    <div className="h-screen w-screen bg-purple grid grid-cols-1 place-items-center">
      <Card className="md:w-96 m-5">
        <CardHeader color="blue" className="relative h-56">
          {state ? (
            <img
              src={`estados/${state}.png`}
              alt="state"
              className="m-auto w-52"
            />
          ) : (
            <img src={`estados/Pendiente.png`}></img>
          )}
        </CardHeader>
        <CardBody className="text-center">
          <Typography variant="h5" className="mb-2">
            {state ? states[state][0] : "La transacción esta siendo procesada"}
            <br />
            <br />
            {state == "Aceptada"
              ? `Recuerde qué el comprobante llegará al correo: ${email} `
              : ""}
          </Typography>
          <br />
          <Typography>
            {state != "Aceptada" ? (razon ? razon : "") : ""}
          </Typography>
          <br />
          <Typography>
            {state
              ? states[state][1]
              : "Su transacción está siendo procesada en otra pestaña y se verificará cuando termine"}
          </Typography>

          <Link to={"/"}>
            <Button className="bg-purple text-white my-5">
              Volver a la pagina
            </Button>
          </Link>
          {state == "Aceptada" && (
            <Button
              className="bg-purple text-white my-5"
              onClick={handleOnClick}
            >
              Descargar Comprobante
            </Button>
          )}
        </CardBody>
        <CardFooter divider className="flex items-center justify-between py-3">
          <Typography variant="small">Pago de factura</Typography>
          <Typography variant="small" color="gray" className="flex gap-1">
            <i className="fas fa-map-marker-alt fa-sm mt-[3px]" />
            FinMaq
          </Typography>
        </CardFooter>
      </Card>
    </div>
  );
}

const states = {
  Pendiente: [
    "La transacción esta siendo procesada.",
    "Su transacción está siendo procesada en otra pestaña y se verificará cuando termine",
  ],
  Rechazada: [
    "La trasacción ha sido rechazada",
    "La transacción ha sido rechazada , si cree que se ha tratado de un error, comunicate con nosotros.",
  ],
  Aceptada: [
    "¡Transacción Exitosa!",
    "La transacción ha resultado exitosa, en caso de algun inconveniente recuerda comunicarte con nosotros.",
  ],
};
