import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";

export default function CardC({ title, description, foot, img }) {
  return (
    <Card className="w-auto md:w-96 shadow-2xl scale-90 hover:scale-100 ease-in duration-100 ">
      <img src={img} alt="state" className="m-auto w-52 mt-10 object-scale-down" />

      <CardBody className="text-center  text-black">
        <Typography variant="h5" className="mb-2">
          {title}
        </Typography>

        <Typography>{description}</Typography>
      </CardBody>
      <CardFooter
        divider
        className="flex items-center justify-between py-3 text-black"
      >
        <Typography variant="small">{title}</Typography>
        <Typography
          variant="small"
          color="gray"
          className="flex gap-1 text-black"
        >
          <i className="fas fa-map-marker-alt fa-sm mt-[3px]" />
          FinMaq | Productos
        </Typography>
      </CardFooter>
    </Card>
  );
}
