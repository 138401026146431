export class Token {
    accesstoken: string
    refreshToken: string
    idToken: string
    expiration: number


    constructor({ accessToken = "", refreshToken = "", idToken = "", expiration = "" }: any) {
        this.accesstoken = accessToken
        this.refreshToken = refreshToken
        this.idToken = idToken
        this.expiration = expiration
    }

}   