


import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import localStorageService from "../utils/localStorage/local-storage.service";
import { Token } from "../app/CRM/entities/token";
import crmService from "../app/CRM/infrastructure/crm-service";
import crmLoginService from "../app/CRM/infrastructure/crm-login-service";
import { ELocalStorageItems } from "../utils/localStorage/local-storage-items.enum";
import LoginResponse from "../app/CRM/entities/login";
import { User } from "../app/CRM/entities/user";
import jwtDecode from "jwt-decode";


export class HttpClientService {

    public user: User
    private client: AxiosInstance;

    private token: Token;

    private constructor(baseUrl: string | undefined, withCredentials: boolean, refreshTokenEndpoint: string) {
        const baseURL = baseUrl || "http://localhost:3001";
        this.token = new Token({})
        this.client = axios.create({ baseURL });
        this.user = new User({});
        this.client.interceptors.response.use((response) => response, (error) => {
            const originalRequest = error.config;
            
            console.log(error)

            window.history.pushState({},"","/login")
            

            return Promise.reject(error);
        })
    }

    private async refreshAccessToken(refreshTokenEndpoint: string) {
        try {
            const token = localStorageService.get(ELocalStorageItems.crmRefreshToken)
            console.log(token)
            const response = await axios.post<LoginResponse>(refreshTokenEndpoint, { RefreshToken: token })
            localStorageService.save(ELocalStorageItems.crmAccessToken, response.data.AuthenticationResult.AccessToken)
            localStorageService.save(ELocalStorageItems.crmRefreshToken, token ?? "")
            this.setToken(new Token({ accessToken: response?.data.AuthenticationResult.AccessToken, refreshToken: response?.data.AuthenticationResult.RefreshToken, tokenId: response?.data.AuthenticationResult.IdToken, expiration: response?.data.AuthenticationResult.ExpiresIn }))
        } catch (err) {
            console.log(err)
            localStorage.clear()
            window.location.href = "/login"
            throw err;
        }
    }

    private getConfigWithHeaders() {
        const config: AxiosRequestConfig = {
            headers: {
                "Authorization": localStorageService.get(ELocalStorageItems.crmAccessToken),
            }
        }
        return config;
    }

    async get<T>(url: string, params?: any) {
        const config = this.getConfigWithHeaders();
        if (!!params)
            config.params = params;

        return this.client.get<T>(url, config);
    }

    async post<T>(url: string, data: any, params?: any) {
        const config = this.getConfigWithHeaders();
        if (!!params)
            config.params = params;


        return this.client.post<T>(url, data, config);
    }

    async patch<T>(url: string, data: any) {
        const config = this.getConfigWithHeaders();
        const response = await this.client.post<T>(url, data, config);
        return response.data;
    }

    async delete<T>(url: string, data: any) {
        const config = this.getConfigWithHeaders();
        const response = await this.client.delete<T>(url, config);
        return response.data;
    }

    static instance(baseUrl: string | undefined, withCredentials: boolean, refreshTokenEndpoint: string) {
        return new HttpClientService(baseUrl, withCredentials, refreshTokenEndpoint);
    }

    public getToken(): string | null {
        return this.token.accesstoken
    }

    public setToken(token: Token) {
        localStorageService.save(ELocalStorageItems.crmAccessToken, token.accesstoken)
        localStorageService.save(ELocalStorageItems.crmRefreshToken, token.refreshToken)
        localStorageService.save(ELocalStorageItems.crmIdToken, token.idToken)
        const user = jwtDecode<any>(token.idToken);
        this.user = new User({
            ...user, username: user["cognito:username"], role: user["custom:role"], access_level: user["custom:accessLevel"]
        })

        this.token = token;
    }


    public verifyToken(): boolean {
        const accessToken = localStorageService.get(ELocalStorageItems.crmAccessToken)
        const refreshToken = localStorageService.get(ELocalStorageItems.crmRefreshToken)
        const idToken = localStorageService.get(ELocalStorageItems.crmIdToken)

        if (!accessToken || !refreshToken || !idToken) {
            return false
        }

        const token = new Token({
            accessToken: accessToken,
            refreshToken: refreshToken,
            idToken: idToken,

        })

        this.setToken(token)

        return true

    }

    private async responseHandler<T>(promesa: Promise<AxiosResponse<T, any>>) {
        try {
            const response = await promesa;

            return response;
        } catch (error: any) {
            console.log(error);
            this.errorHandler(error);
            return error;
        }
    }

    private async errorHandler(error: AxiosError) {
        if (error.code == "403") {

        }
    }
}


const httpClientService = HttpClientService.instance(process.env.REACT_APP_PASARELA_BASE_URL, false, "");
export default httpClientService;
