
import { HttpClientService } from "../../../api/api";
import { SimularProducto } from "../entities/simular-productos";

class SimuladorService {

    private urlSimulador = `/simular`;

    public async simulador(simularProducto : SimularProducto) {
        let simulacion = await SimuladorApi.post(this.urlSimulador, simularProducto);
        console.log(simulacion)
        return simulacion.data;
    }
}

const SimuladorApi = HttpClientService.instance(process.env.REACT_APP_SIMULADOR_BASE_URL, false, "")

const simulador = new SimuladorService();

export default simulador;