export class User {
    sub: string
    iss: string
    "cognito:username": string
    origin_jti: string
    aud: string
    event_id: string
    token_use: string
    auth_time: number
    exp: number
    "custom:role": string
    "custom:accessLevel": string
    iat: number
    jti: string
    email: string


    constructor({
        sub,
        iss,
        origin_jti,
        aud,
        event_id,
        token_use,
        auth_time,
        exp,
        username,
        role,
        iat,
        jti,
        email,
        access_level
    }: any) {
        this.sub = sub
        this.iss = iss
        this["cognito:username"] = username
        this.origin_jti = origin_jti
        this.aud = aud
        this.event_id = event_id
        this.token_use = token_use
        this.auth_time = auth_time
        this.exp = exp
        this["custom:role"] = role
        this["custom:accessLevel"] = access_level
        this.iat = iat
        this.jti = jti
        this.email = email
    }
}