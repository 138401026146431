import { HttpClientService } from "../../../api/api";
import LoginResponse from "../entities/login";
import { Token } from "../entities/token";

class CRMLoginService {

    private urlLogin = `/auth`;
    private urlRefesh = `/refesh`;

    public async Login(username: string, password: string){
        let resp = await CRMLoginApi.post<LoginResponse>(this.urlLogin, { username, password })
        
        return resp;
    }

    public async RefeshToken(token: Token): Promise<Token> {
        let resp = await CRMLoginApi.post<LoginResponse>(this.urlRefesh, { refreshToken: token.refreshToken })
        const newToken = new Token({ accessToken: resp?.data.AuthenticationResult.AccessToken, refreshToken: resp?.data.AuthenticationResult.RefreshToken, idToken: resp?.data.AuthenticationResult.IdToken, expiration: resp?.data.AuthenticationResult.ExpiresIn })
        
        return newToken;
    }
}

const CRMLoginApi = HttpClientService.instance(process.env.REACT_APP_CRM_BASE_URL_LOGIN, false, "")

const crmLoginService = new CRMLoginService();


export default crmLoginService