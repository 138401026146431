import { useMemo } from "react";

export default function ColumnsRenting() {
  const columns = useMemo(
    () => [
      {
        Header: "Numero",
        accessor: "numero",
      },
      {
        Header: "Días",
        accessor: "dias",
      },
      {
        Header: "Fecha Inicial",
        accessor: "fechaInicial",
        Cell: ({ value }: { value: any }) => {
          const date = new Date(value);
          return `${date.getDate()}-${
            date.getMonth() + 1
          }-${date.getFullYear()}`;
        },
      },
      {
        Header: "Fecha Final",
        accessor: "fechaFinal",
        Cell: ({ value }: { value: any }) => {
          const date = new Date(value);
          return `${date.getDate()}-${
            date.getMonth() + 1
          }-${date.getFullYear()}`;
        },
      },
      {
        Header: "Canon",
        accessor: (d: any) => {
          return d.canonGravado + d.canonExento;
        },
        Cell: ({ value }: { value: any }) => {
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });

          return formatter.format(value);
        },
      },
      {
        Header: "Canon + IVA",
        accessor: "pago",
        Cell: ({ value }: { value: any }) => {
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });

          return formatter.format(value);
        },
      },
    ],
    []
  );
  return columns;
}
