import React from "react";
import { Link } from "react-scroll";
import { Link as Go } from "react-router-dom";

export default function Footer({ links = false }) {
  return (
    <div className="relative grid md:flex md:justify-between md:items-center md:px-10 text-white bg-purple  space-y-10 footer md:py-10">
      <img
        src="resources/triangles/white_triangles.png"
        className="absolute top-0 left-0 w-56"
        alt=""
      />
      <img
        src="logos/logo_white_finmaq.png"
        className="w-56 mx-auto md:object-scale-down md:mx-10 my-5"
        alt=""
      />
      {links && (
        <div className="mx-auto md:mx-0">
          <ul className="flex-col  text-left font-light cursor-pointer">
            <li>
              <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Inicio
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="who-we-are"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                ¿Quiénes somos?
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="financial-solutions"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Soluciones financieras
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="partners"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Alianzas comerciales
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="partners"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Contacto
              </Link>
            </li>
          </ul>
          <ul className="font-light">
            <Go to={"/aviso-privacidad"}>
              <li className="mr-52">Aviso de privacidad</li>
            </Go>
          </ul>
          <ul className="font-light">
            <Go to={"/politicas-de-tratamiento-datos"}>
              <li className="mr-2">Politicas de tratamiento de datos personales</li>
            </Go>
          </ul>
        </div>
      )}

      <div className="mx-auto">
        <ul className="font-light">
          <li className="font-medium">Contactenos en:</li>
          <li>301 710 6346 - 300 745 5979</li>
          <li>Cra 10 #97a -13</li>
          <li>administracion@finmaq.com.co</li>
        </ul>
      </div>

      <div className=" font-medium mx-auto">
        Somos parte de:
        <img
          src="resources/socialmedia/fintechco.png"
          className="w-56 mx-auto my-5"
          alt=""
        />
      </div>
    </div>
  );
}
