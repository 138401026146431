import React, { useEffect, useState } from 'react';
import { Widget } from '@typeform/embed-react';

interface ModalEncuestaProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ModalEncuestaDistribuidores({ isOpen, onClose }: ModalEncuestaProps) {
  const [isFormCompleted, setIsFormCompleted] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false); 

  useEffect(() => {
    const formCompleted = localStorage.getItem('formCompleted');
    if (formCompleted === 'true') {
      setIsFormCompleted(true);
    }
  }, []);

  const handleFormSubmit = () => {
    setIsFormCompleted(true);
    localStorage.setItem('formCompleted', 'true');
    onClose();
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (!isOpen || isFormCompleted) return null;

  return (
    <div className="fixed bottom-0 right-0 z-10 mb-2 mr-2 shadow-2xl">
      <div className={`bg-white rounded-lg shadow-lg transition-all duration-300 ${isCollapsed ? 'w-64 h-20' : 'w-96 h-auto'}`}>
        <div className="flex justify-between px-6 py-4 border-b">
          <h2 className="text-lg font-semibold">
            {isCollapsed ? 'Completa este formulario' : 'Completa el siguiente formulario:'}
          </h2>
          <button onClick={toggleCollapse} className="w-10 h-10 mt-2">
            {isCollapsed ? (
              <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 15L12 9L6 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            ) : (
              <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            )}
          </button>
        </div>
        
        {!isCollapsed && ( 
          <div className="px-6 py-4 h-96">
            <Widget
              id="https://2in1pjsxtan.typeform.com/to/Bhp06V0V/"
              style={{ width: '100%', height: '100%' }}
              className="my-form"
              onSubmit={handleFormSubmit}
            />
          </div>
        )}
      </div>
    </div>
  );
}
