import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { BsPrinter } from "react-icons/bs";
import { useReactToPrint } from "react-to-print";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export default function Comprobante() {
  const { state } = useLocation();
  const comprobante = useRef();
  const handlePrint = useReactToPrint({
    content: () => comprobante.current,
    documentTitle: `Comprobante Pago ${state.descripcion}`,
  });
  console.log(state);

  return (
    <div className="grid justify-center " ref={comprobante}>
      <div className="flex flex-col items-center m-2">
        <div className="w-auto md:w-[640px] bg-purple flex justify-center rounded-md my-5">
          <img
            src="logos/logo_white_finmaq.png"
            className="px-20 py-5 w-auto md:w-[500px] "
            alt="finmaq"
          />
        </div>
        <div className="w-auto md:w-[600px] bg-gray-200 flex flex-col items-center p-10 m-2 rounded-md">
          <p className="text-xl font-semibold">Comprobante de pago FinMaq</p>
          <div
            className="rounded-lg bg-purple no-print text-white p-2 mt-4"
            onClick={handlePrint}
          >
            {" "}
            Imprimir{" "}
          </div>
          <div className="p-5">
            Has realizado una transacción a FinMaq, a continuación se le
            mostrará un resumen:
          </div>
          <div className="text-center text-green-500">
            Transacción Aceptada <br />
            <span className="text-2xl">{formatter.format(state.valor)}</span>
          </div>
        </div>
        <div className="w-auto md:w-[600px] bg-gray-200 flex flex-col items-center p-10 rounded-md m-2">
          <table className="w-full">
            <tr >
              <td className="p-2 font-medium">Nombres:</td>
              <td className="p-2">{state.nombres}</td>
            </tr>
            <tr>
              <td className="p-2 font-medium">Fecha de pago:</td>
              <td className="p-2">{state.fecha}</td>
            </tr>
            <tr>
              <td className="p-2 font-medium">Email:</td>
              <td className="p-2">{state.email}</td>
            </tr>
            <tr>
              <td className="p-2 font-medium">Banco:</td>
              <td className="p-2">{state.banco}</td>
            </tr>
            <tr>
              <td className="p-2 font-medium">Detalle:</td>
              <td className="p-2">{state.descripcion}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}
