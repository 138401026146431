import { HttpClientService } from "../../../api/api";

class CRMRefreshDB {

    private urlRefresh = `/update`


    public async RefreshDB() {
        const resp = await CRMRefreshApi.get(this.urlRefresh);

        return resp

    }


}



const CRMRefreshApi = HttpClientService.instance(process.env.REACT_APP_CRM_REFRESH_DB_BASE_URL || "http://127.0.0.1:3000", false, "")

const crmRefreshDBService = new CRMRefreshDB();


export default crmRefreshDBService;