import { useMemo } from "react";

    export default function ColumnsCredito () {
        const columns = useMemo (
        () => [
            {
                Header : "Numero",
                accessor: "numero"
            },
            {
                Header : "Días",
                accessor: "dias"
            },
            {
                Header : "Fecha Inicial",
                accessor: "fechaInicial",
                Cell: ({value} : {value: any}) => {    
                    const date = new Date(value)
                    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
                }
            },
            {
                Header : "Fecha Final",
                accessor: "fechaFinal",
                Cell: ({value}) => {    
                    const date = new Date(value)
                    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
                }
            },
            {
                Header : "Saldo Inicial",
                accessor: "saldoInicial",
                Cell: ({value}) => {
                    const formatter = new Intl.NumberFormat("en-US",
                    {
                        style: "currency",
                        currency : "USD"
                    });
                    return formatter.format(value);
                }
            },
            {
                Header : "Capital",
                accessor: "capital",
                Cell: ({value}) => {
                    const formatter = new Intl.NumberFormat("en-US",
                    {
                        style: "currency",
                        currency : "USD"
                    });
                    return formatter.format(value);
                }
            },
            {
                Header : "Intereses",
                accessor: "intereses",
                Cell: ({value}) => {
                    const formatter = new Intl.NumberFormat("en-US",
                    {
                        style: "currency",
                        currency : "USD"
                    });
                    return formatter.format(value);
                }
            },
            {
                Header : "Saldo Final",
                accessor: "saldoFinal",
                Cell: ({value}) => {
                    const formatter = new Intl.NumberFormat("en-US",
                    {
                        style: "currency",
                        currency : "USD"
                    });
                    return formatter.format(value);
                }
            },
            {
                Header : "Pago",
                accessor: "pago",
                Cell: ({value}) => {
                    const formatter = new Intl.NumberFormat("en-US",
                    {
                        style: "currency",
                        currency : "USD"
                    });
                    return formatter.format(value);
                }
            }], [])
        return columns
    }