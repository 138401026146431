import React, { useState } from "react";
import NavBar from "../components/NavBar";
import { Input } from "@material-tailwind/react";
import { FaAddressCard } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import api from "../api/api";

export default function Pagos() {
  const navigate = useNavigate();

  const [documento, setDocumento] = useState("");

  const [noPendingForm, setNoPendingForms] = useState();

  const getInvoces = async () => {
    setNoPendingForms(false);

    api
      .get(`/products/${documento}`)
      .then((resp) => {
        console.log(resp);
        const facturas = resp["data"]["data"];
        console.log(facturas);
        if (facturas.length === 0) {
          setNoPendingForms(true);
        } else {
          navigate(`/pagos/${documento}`, { state: facturas });
        }
      })
      .catch((resp) => {
        console.log(resp);
      });
  };

  return (
    <>
      <NavBar bgColor="bg-purple" back></NavBar>
      <div className=" py-5 md:grid md:grid-cols-2 h-[80vh] md:place-items-center">
        <div>
          <div className="bg-slate-200 flex flex-col items-center space-y-3 p-5">
            <h3 className="text-2xl text-purple font-semibold">
              Pasarela de pagos
            </h3>
            <h2 className="text-center">
              Ahora puedes consultar tu factura y hacer pagos fácilmente
            </h2>
          </div>

          <div className="bg-slate-200 flex flex-col items-center space-y-3 p-5">
            <Input
              label="Documento "
              className="bg-blue-gray-50"
              type="tel"
              color="indigo"
              value={documento}
              onChange={(e) => {
                setDocumento(e.target.value);
              }}
              icon={<FaAddressCard />}
            ></Input>
          </div>
          <div className="flex flex-col items-center w-full">
            <button
              className="mx-10 place-self-center w-max  bg-purple hover:bg-purple/80 px-6 py-2 text-white font-medium rounded-xl"
              onClick={getInvoces}
            >
              Consultar
            </button>
          </div>
          {noPendingForm && (
            <div className="text-red-300 text-center p-5">
              La cedula qué ha digitado no tiene facturas pendientes.
            </div>
          )}
          <div className="text-center text-purple font-semibold py-5">
            <a
              href="https://wa.link/2ilmio"
              target={"_blank"}
              rel="noreferrer"
              className=""
            >
              ¿Necesitas Soporte? Comunicate con nosotros
            </a>
          </div>
        </div>
        <div className="hidden md:block">
          <img src="resources/sections/service.png" className="w-96" alt="" />
        </div>
      </div>
      <Footer />
    </>
  );
}
