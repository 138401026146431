import React from "react";
import Field from "./TableField";
import { BsArrowRight } from "react-icons/bs";
import moment from "moment/moment";

export default function Factura({ factura }) {
  const fecha = new Date(factura["expirationDate"]);
  const fechaPago = new Date(fecha.getTime() - 60*60*24*21*1000)

 
  const MM = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Augosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  return (
    <>
      <table className="cursor-pointer inline-block rounded-lg m-4 border-4 border-purple shadow-2xl bg-gray-100 hover:bg-gray-200 scale-[90%] hover:scale-100 ease-in duration-100 ">
        <tbody className="">
          <Field name={"Nombre"} data={factura["name"]} />
          <Field name={"Documento"} data={factura["docNumber"]} />
          <Field name={"Celular"} data={factura["cellPhone"]} />
          <Field name={"Correo"} data={factura["email"]} />
          <Field name={"Referencia"} data={factura["numProduct"]} />
          <Field name={"Número de producto"} data={factura["oldNumber"]} />
          <Field
            name={"Fecha de pago"}
            data={fechaPago.toLocaleDateString()}
          />
          <Field
            name={"Valor a pagar"}
            data={factura["amount"]}
            bold="font-medium"
            money
          />
        </tbody>
        <div className="w-full flex text-lg justify-end space-x-6 bg-purple text-white  items-center self-end">
                <p> Ir a pagar</p>
                <BsArrowRight className="" size={30} />
          </div>
      </table>
    </>
  );
}
