import FieldProps, { InputTypes } from "./text-field-props";
import * as React from 'react';

const SelectField = ({model, setModel, campoId, placeholder, children, type, required, onReset}: FieldProps) => {
    return (
        <div className="">
            <div className="text-sm p-0 m-0 text-[#7ebfdf] font-semibold w-52">{placeholder}</div>
            <select
                required={required}
                className='w-52 text-blue-gray-700 font-sans font-normal px-3 py-2.5 rounded-[7px] border  focus:outline-none focus:border-2 border-[#7ebfdf] focus:border-[#7ebfdf]'
                placeholder={placeholder}
                onChange={(e) => {
                    const esNumero = (type === InputTypes.Number);
                    const valor = esNumero ? parseFloat(e.target.value) : e.target.value;
                    model[campoId] = valor;
                    setModel({...model});
                    onReset?.();
                }}
                value={model[campoId]}
            >
                {children}
            </select>
        </div>
    );
}

export default SelectField;