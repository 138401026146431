import { Button } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";


export default function PreappovedFail() {

    const navigator = useNavigate();

  return (
    <div className="grid content-center justify-center w-screen h-screen">
      <div className="flex flex-col items-center w-[300px]">
        <img src="logos/logo_blue.png" className="w-24 py-2" alt="" />
        <div className="text-xl text-blue font-medium">FinMaq</div>
        <div className="text-xl py-2 font-medium">Tu solicitud ha fallado</div>
        <div className=" py-2 text-justify font-light">
          Parece que tu propuesta de credito ha <b>Caducado</b> o ha tenido
          algún error en el proceso, puedes solicitar otra propuesta de crédito contactando con
          nosotros. 
        </div>
        <Button
          className="bg-blue text-white my-5"
          onClick={() => {
            navigator("/");
          }}
        >
          Volver a la pagina web
        </Button>
      </div>
    </div>
  );
}
