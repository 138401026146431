import React, { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTable, usePagination, useSortBy, useExpanded } from "react-table";
import { BiFirstPage, BiLastPage } from "react-icons/bi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
//import { Navigate, useNavigate } from "react-router";
//import { Link } from "react-router-dom";

export default function Table(props: any) {
  const columns = props.columns;

  const data = useMemo(() => props.data, [props.data]);
  //const navigate = useNavigate();
  const initialState = props.paginate
    ? {
      initialState: {
        pageIndex: props.paginate.page,
        pageSize: props.paginate.page_size,
      },
    }
    : {};

  const table = useTable(
    {
      columns,
      data,
      initialState:{
        pageIndex: 0,
        pageSize: 100,
      }
    },
    useSortBy,
    useExpanded,
    useExpanded,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = table;

  return (
    <>
      <table
        {...getTableProps()}
        className={`shadow-lg border-collapse rounded-lg m-5 `+ props.className } 
      >
        <thead className="bg-[#7ebfdf] text-white rounded">
          {
            headerGroups.map((headerGroup: { getHeaderGroupProps: () => JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableRowElement> & React.HTMLAttributes<HTMLTableRowElement>; headers: any[]; }) => (
              // Añadimos las propiedades al conjunto de columnas
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()} className="brd">
                {
                  // Recorremos cada columna del conjunto para acceder a su información
                  headerGroup.headers.map((column) => (
                    // Añadimos las propiedades a cada celda de la cabecera
                    // eslint-disable-next-line react/jsx-key
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={
                        column.isSorted
                          ? column.isSortedDesc
                            ? "desc p-5"
                            : "asc p-5"
                          : "p-5"
                      }
                    >
                      {column.render("Header")}
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            // Recorremos las filas
            page.map((row: { getRowProps: () => JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableRowElement> & React.HTMLAttributes<HTMLTableRowElement>; cells: { getCellProps: () => JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableDataCellElement> & React.TdHTMLAttributes<HTMLTableDataCellElement>; column: { Header: any; }; value: any; render: (arg0: string) => string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }[]; }) => {
              // Llamamos a la función que prepara la fila previo renderizado
              prepareRow(row);
              return (
                // Añadimos las propiedades a la fila
                // eslint-disable-next-line react/jsx-key
                <tr {...row.getRowProps()} className="hover:bg-gray-300">
                  {
                    // Recorremos cada celda de la fila
                    row.cells.map((cell: { getCellProps: () => JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableDataCellElement> & React.TdHTMLAttributes<HTMLTableDataCellElement>; column: { Header: any; }; value: any; render: (arg0: string) => string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
                      // Añadimos las propiedades a cada celda de la fila

                      return (
                        // eslint-disable-next-line react/jsx-key
                        <td
                          {...cell.getCellProps()}
                          className={`py-2 px-5 text-center ${cell.column.Header == props.selectedColumn
                              ? "cursor-pointer"
                              : ""
                            }`}
                          onClick={() => {
                            if (cell.column.Header == props.selectedColumn) {
                              router.push(props.selectedRoute + cell.value)

                              console.log(cell);
                            }
                          }}
                        >
                          {
                            // Pintamos el contenido de la celda
                            cell.render("Cell")
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
      {props.paginate && !props.amortizations && (
        <div className="pagination flex flex-col justify-center align-middle ">
          <div className="controls self-center">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <BiFirstPage size={40} className="page-controller text-white" />
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              <MdKeyboardArrowLeft size={40} className="page-controller text-white" />
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              <MdKeyboardArrowRight size={40} className="page-controller text-white"  />
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <BiLastPage size={40} className="page-controller text-white" />
            </button>
          </div>
          <select
            className="text-md w-52 self-center border-[#7ebfdf] border-2 p-2 rounded-md text-center text-black"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[12, 24, 36, 48].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
}
