enum PowerBIReports {

    ProyeccionDesembolsos = "9cb141ed-2d5a-44da-be75-c9a20c081378",
    FunnelComerciales = "50a01833-411a-4345-82ff-d890f8df5ff3",
    Preaprobados = "4ab8e0ea-4be5-414f-acbf-fbddf0e8fc95",
    GroupPowerBIEmbed = "bb747530-d0ab-4382-b8bc-6090357d8454",
    InformeJunta = "5b100c52-79aa-467d-b855-be611c4e27c9",
    BaseGeneral = "686d859d-8aa9-40a4-af42-84dffd3c89ff",
    InformeDistribuidor = "ac74d1d3-4f6f-4dac-b9b2-d19ca3686ed7",
    InformeComercial = "fe335189-65bc-4b3d-afdf-6a6deebb422a",
    Tiempos = "b510dd09-7171-4251-a983-21a5f817c06e",
    SaludCartera = "f6ff70d9-029e-48c7-a7e4-a8d86b5e0347",
    MetricasUsoDistribuidores = "2ff00159-f910-4fd1-a614-e233ffab8f27",
    ModeloSemanticoMetricas = "9cedbca2-9d98-4c8a-98a3-9cf0f35aac9b",
    DatasetCoreMetricas = "821a270a-8ce1-4bbb-9c14-438f5c1437c1"
}


enum XMLAPermissions {
    ReadOnly = "ReadOnly",
    Off = "Off"
}

interface DatasetParams{
    id: string
    xmlaPermissions: XMLAPermissions
    semanticModel?: boolean
}

interface Reports{
    id: string
}

interface RequestGetToken{
    datasets: DatasetParams[]
    reports: Reports[]
    needsIdentities: boolean
}


interface InfoReport {

    id: string,
    name: string,
    datasets: DatasetParams[],
    needsIdentities: boolean
    embedUrl?: string | null,
    accessToken?: string | null,
    exp?: number | null,


}

interface IReportsByRol {
    [key: string]: InfoReport[]
}



const ReportsByRol = (rol : string ,distribuidor: string) => {
    let roles : IReportsByRol = {
        "admin": [
            {
                id: PowerBIReports.Preaprobados,
                name: "Preaprobados",
                needsIdentities: true,
                datasets: [{id:PowerBIReports.BaseGeneral,xmlaPermissions:XMLAPermissions.ReadOnly,semanticModel:true}]
                
            },
            {
                id: PowerBIReports.ProyeccionDesembolsos,
                name: "Proyección Desembolso",
                needsIdentities: true,
                datasets: [{id:PowerBIReports.BaseGeneral,xmlaPermissions:XMLAPermissions.ReadOnly,semanticModel:true}]
            },
            {
                id: PowerBIReports.InformeJunta,
                name: "Informe Junta",
                needsIdentities: true,
                datasets: [{id:PowerBIReports.BaseGeneral,xmlaPermissions:XMLAPermissions.ReadOnly,semanticModel:true}],
            },
            {
                id: PowerBIReports.InformeDistribuidor,
                name: "Informe Distribuidores",
                needsIdentities: true,
                datasets: [{id:PowerBIReports.BaseGeneral,xmlaPermissions:XMLAPermissions.ReadOnly,semanticModel:true}],
            },
            {
                id: PowerBIReports.FunnelComerciales,
                name: "Funnels Comercial",
                needsIdentities: true,
                datasets: [{id:PowerBIReports.BaseGeneral,xmlaPermissions:XMLAPermissions.ReadOnly,semanticModel:true}]
            },
            {
                id: PowerBIReports.Tiempos,
                name: "Tiempos",
                needsIdentities: true,
                datasets: [{id:PowerBIReports.BaseGeneral,xmlaPermissions:XMLAPermissions.ReadOnly,semanticModel:true}]
            },
            {
                id: PowerBIReports.MetricasUsoDistribuidores,
                name: "Metricas Uso Distribuidores",
                needsIdentities: false,
                datasets: [{id:PowerBIReports.ModeloSemanticoMetricas,xmlaPermissions:XMLAPermissions.ReadOnly,semanticModel:true},{id:PowerBIReports.DatasetCoreMetricas,xmlaPermissions:XMLAPermissions.ReadOnly}]
            }, 
        ],
        "inversionistas": [
            {
                id: PowerBIReports.InformeJunta,
                name: "Informe de Junta",
                needsIdentities: true,
                datasets: [{id:PowerBIReports.BaseGeneral,xmlaPermissions:XMLAPermissions.ReadOnly,semanticModel:true}]
            },
            {
                id: PowerBIReports.Tiempos,
                name: "Tiempos",
                needsIdentities: true,
                datasets: [{id:PowerBIReports.BaseGeneral,xmlaPermissions:XMLAPermissions.ReadOnly,semanticModel:true}]
            }
        ],
        "distribuidor": [
            {
                id: PowerBIReports.InformeDistribuidor,
                name: "Distribuidor " + distribuidor,
                needsIdentities: true,
                datasets: [{id:PowerBIReports.BaseGeneral,xmlaPermissions:XMLAPermissions.ReadOnly,semanticModel:true}]
            },
            {
                id: PowerBIReports.Tiempos,
                name: "Tiempos",
                needsIdentities: true,
                datasets: [{id:PowerBIReports.BaseGeneral,xmlaPermissions:XMLAPermissions.ReadOnly,semanticModel:true}]
            }
        ],
        "comercial": [
            {
                id: PowerBIReports.InformeComercial,
                name: "Comercial",
                needsIdentities: true,
                datasets: [{id:PowerBIReports.BaseGeneral,xmlaPermissions:XMLAPermissions.ReadOnly,semanticModel:true}]
            },
            {
                id: PowerBIReports.FunnelComerciales,
                name: "Funnels Comercial",
                needsIdentities: true,
                datasets: [{id:PowerBIReports.BaseGeneral,xmlaPermissions:XMLAPermissions.ReadOnly,semanticModel:true}]
            },
            {
                id: PowerBIReports.Tiempos,
                name: "Tiempos ",
                needsIdentities: true,
                datasets: [{id:PowerBIReports.BaseGeneral,xmlaPermissions:XMLAPermissions.ReadOnly,semanticModel:true}]
            }
        ],
    }

    return roles[rol]
}




export { PowerBIReports, InfoReport, ReportsByRol, RequestGetToken }