import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";
import crmService from "../../app/CRM/infrastructure/crm-service";

export default function ProtectedRoute({ redirectPath = "/" }) {
  const [verificando, setVerificando] = React.useState(true);
  const [Logged, setLogged] = React.useState(null);

  React.useEffect(() => {
    const verify = crmService.verifyLogin();
    setLogged(verify);
    setVerificando(false);
  }, []);

  if (!Logged && Logged != null) return <Navigate to={redirectPath} />;
  else return <div>{!verificando && <Outlet />}</div>;
}
