import React from "react";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

export default function PreappovedConfirm() {
  const navigator = useNavigate();

  return (
    <div className="grid content-center justify-center w-screen h-screen">
      <div className="flex flex-col items-center w-[300px]">
        <img src="logos/logo_blue.png" className="w-24 py-2" alt="" />
        <div className="text-xl text-blue font-medium">FinMaq</div>
        <div className="text-xl py-2 font-medium">Tu solicitud fue enviada</div>
        <div>Gracias por confiar en nosotros.</div>
        <div className=" py-2 text-justify font-light">
          Tu solicitud fue recibida y está siendo procesada,
          <b> tendrás respuesta entre 2 a 5 minutos a tu correo electronico.</b>
        </div>
        <Button
          className="bg-blue text-white my-5"
          onClick={() => {
            navigator("/");
          }}
        >
          Volver a la pagina web
        </Button>
      </div>
    </div>
  );
}
