import React, { useState, useEffect } from "react";
import { Select, Option, Input, Checkbox } from "@material-tailwind/react";
import {
  useLocation,
} from "react-router-dom";
import Field from "../components/TableField";
import CurrencyFormat from "react-currency-format";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ReactLoading from "react-loading";
import api from "../api/api";

export const useMountEffect = (fun) => useEffect(fun, []);

const useFocus = (initialFocus = false, id = "") => {
  const [focus, setFocus] = useState(initialFocus);
  const setFocusWithTrueDefault = (param) =>
    setFocus(isBoolean(param) ? param : true);
  return [
    setFocusWithTrueDefault,
    {
      autoFocus: focus,
      key: `${id}${focus}`,
      onFocus: () => setFocus(true),
      onBlur: () => setFocus(false),
    },
  ];
};

export default function PayFactura() {
  const { state } = useLocation();
  const factura = state;

  const [petitionSuccess, setPetitionSuccess] = useState(false);
  const [banks, setBanks] = useState({});

  const [amount, setAmount] = useState(parseInt(factura["amount"]));
  const [maxAmount, setmaxAmount] = useState(parseInt(factura["maxAmount"]));
  const [disabled, setDisabled] = useState(true);
  const renting = factura["numProduct"].slice(0, 2) === "RE" ? true : false;
  const [urlEpayco, setUrlEpayco] = useState();
  const [inputFocus, setInputFocus] = useFocus();
  const [messageError, setMessageError] = useState();

  const [selectedBank, setSelectedBank] = useState("");

  const [errorTransact, setErrorTransact] = useState(false);
  const [loadingTransact, setloadingTransact] = useState(false);

  const [typeDocument, setTypeDocument] = useState("");
  const [document, setDocument] = useState("");
  const [name, setName] = useState("");
  const [cellphone, setCellphone] = useState();
  const [email, setEmail] = useState();

  const [setFocus, focusProps] = useFocus(true);
  const [ip, setIP] = useState();

  useEffect(() => {
    getBanks();

    getIP();
  }, []);

  //creating function to load ip addr}  ess from the API
  const getIP = async () => {
    await api
      .get("https://geolocation-db.com/json/")
      .then((resp) => {
        setIP(resp.data.IPv4);
      })
      .catch(() => {
        getIP();
      });
  };

  const validatorField = (value, mensaje) => {
    const isValid = !!value;
    if (!isValid)
      setMessageError(mensaje);
    return isValid;
  }

  const validatorCellphone = (cellPhone) => {
    const regexCellPhoneFormat = /^3[0-9]{9}$/;
    const mensajeError = "Ingresa un número de celular válido";
    return validatorField(regexCellPhoneFormat.test(cellPhone), mensajeError);
  }

  const validatorEmail = (email) => {
    const regexEmailFormat = /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/;
    const mensajeError = "Ingresa un correo electrónico válido";
    return validatorField(regexEmailFormat.test(email), mensajeError);
  }

  const setStateValidForm = (isValid) => {
    setErrorTransact(!isValid);
    setloadingTransact(isValid);
  }

  const handleSendPay = async () => {
    let formIsValid = validatorField(typeDocument, "Selecciona el tipo de identificación");
    formIsValid = formIsValid && validatorField(document, "Ingresa tu documento para continuar");
    formIsValid = formIsValid && validatorField(name, "Ingresa tu nombre completo");
    formIsValid = formIsValid && validatorCellphone(cellphone);
    formIsValid = formIsValid && validatorEmail(email);
    formIsValid = formIsValid && validatorField(selectedBank, "Selecciona el banco para continuar");
    formIsValid = formIsValid && validatorField(amount <= maxAmount, "Ingresaste un valor mayor al que adeudas, recuerda que si quieres liquidar el credito debes llamar para realizar el debido proceso.");

    setStateValidForm(formIsValid);
    
    if (!formIsValid)
      return;
    
    const body = {
      bank: selectedBank,
      description: `Pago de factura numero ${factura.numProduct} de ${factura.name}`,      
      ip: ip,
      extra1: factura.oldNumber,
      numProduct: factura.numProduct,
      docType: typeDocument,
      docNumber: document,
      name: name,
      lastName: '',
      cellPhone: cellphone,
      email: email,
      urlResponse: `https://finmaq.com.co/pagos/confirm/${factura.patrimony}?numProduct=${factura.numProduct}`,
      value: amount.toString(),
    };
  
    const patrimony = factura["patrimony"];

    api
      .post(`/transactions?patrimony=${patrimony}`, body)
      .then((resp) => {
        console.log(resp);
        const transactData = resp["data"]["data"];
        const urlbank = transactData["urlbanco"];
        setloadingTransact(false);

        window.open(urlbank, "_self");
      })
      .catch((resp) => {
        console.log(resp);
        //const data = resp["data"]["data"];
        const errorMsg = resp["message"];
        console.log(errorMsg);
        setMessageError(errorMsg);
        setErrorTransact(true);
        setloadingTransact(false);
      });
  };

  const getBanks = async () => {
    api
      .get(`/banks?patrimony=${factura["patrimony"]}`)
      .then((resp) => {
        const respBanks = resp["data"];
        console.log(respBanks);
        setBanks(respBanks);
        setPetitionSuccess(true);
      })
      .catch((resp) => {
        console.log(resp);
        setPetitionSuccess(false);
      });
  };

  return (
    <>
      <NavBar bgColor="bg-purple" back />
      <div className="min-h-[80vh] h-full">
        <div className="bg-slate-200 flex flex-col items-center space-y-3 pt-5">
          <h3 className="text-2xl text-purple font-semibold">
            Resumen de Factura
          </h3>{" "}
          <h2 className="text-center">
            Revisa que la información sea correcta para proseguir
          </h2>
        </div>

        <div className="p-2 h-full grid grid-cols-1 place-items-center">
          <Resume
            factura={factura}
            renting={renting}
            amount={amount}
            setAmount={setAmount}
            disabled={disabled}
            setDisabled={setDisabled}
            focusProps={focusProps}
            setInputFocus={setInputFocus}
          ></Resume>

          <div className="flex items-center mt-10">
            <Checkbox
              color="indigo"
              value={disabled}
              onChange={(e) => {
                setDisabled(!disabled);
                if (disabled) {
                  setFocus(true);
                  setAmount("0");
                } else {
                  setAmount(factura["amount"]);
                }
              }}
            />
            <div className="text-lg">Pagar otro valor</div>
          </div>

          <div className="py-5 space-y-5">
            <Select
              value={typeDocument}
              onChange={(e) => {
                setTypeDocument(e);
              }}
              className="text-sm "
              label="tipo documento"
              required="true"
            >
              <Option value={""}>Seleciona</Option>
              <Option value={"CC"}>Cedula de Ciudadania (CC)</Option>
              <Option value={"CE"}>Cedula de extrangeria (CE)</Option>
              <Option value={"NIT"}>NIT</Option>
            </Select>
            <Input
              label="Documento"
              required="true"
              onChange={(e) => {
                setDocument(e.target.value);
              }}
            />
            <Input
              label="Nombre"
              required="true"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <Input
              label="Celular"
              required="true"
              onChange={(e) => {
                setCellphone(e.target.value);
              }}
            />
            <Input
              label="E-mail"
              required="true"
              type={"email"}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <div className="text-lg center ">Seleccioné su banco</div>

            {petitionSuccess && (
              <Select
                value={selectedBank}
                onChange={(e) => {
                  setSelectedBank(e);
                }}
                className="text-base"
              >
                {banks["data"].map((u) => (
                  <Option value={u["bankCode"]}>{`${u["bankName"]}`}</Option>
                ))}
              </Select>
            )}
            {!petitionSuccess && (
              <div className="w-full flex justify-center">
                <ReactLoading className="" color="#390094" type="bars" />
              </div>
            )}

            <div className="flex flex-col items-center p-5">
              <button
                onClick={handleSendPay}
                className="mx-10 place-self-center w-max  bg-purple hover:bg-purple/80 px-6 py-2 text-white text-lg rounded-xl"
              >
                Ir a pagar
              </button>
            </div>
          </div>
          {errorTransact && (
            <>
              <div className=" text-red-500">
                Error al procesar la solicitud, por favor intenta nuevamente
              </div>
              <div className=" text-red-500 text-center pt-5">
                {messageError}
              </div>
            </>
          )}
          {loadingTransact && (
            <div className="w-full flex justify-center">
              <ReactLoading className="" color="#390094" type="bars" />
            </div>
          )}
          <div className="text-center text-purple font-semibold py-5">
            <a
              href="https://wa.link/2ilmio"
              target={"_blank"}
              rel="noreferrer"
              className=""
            >
              ¿Necesitas Soporte? Comunicate con nosotros
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

const isBoolean = (param) => typeof param === "boolean";

function Resume({
  factura,
  amount,
  setAmount,
  renting,
  disabled,
  setDisabled,
  focusProps,
}) {
  const handleFocus = (e) => {
    console.log(e);
    e.target.select();
  };

  return (
    <table className="inline-block rounded-lg border-4  border-purple shadow-2xl bg-gray-100">
      <Field name={"Nombre"} data={factura["name"]} />
      <Field name={"Referencia"} data={factura["numProduct"]} />
      <Field name={"Número de producto"} data={factura["oldNumber"]} />
      <Field
        component
        name={"Valor a pagar"}
        data={
          <div className="">
            <p className="py-2 px-4 ">Ingrese el valor</p>
            <CurrencyFormat
              thousandSeparator={true}
              prefix={"$"}
              size={12}
              value={amount}
              onFocus={handleFocus}
              type={"tel"}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                console.log(value, formattedValue);
                setAmount(value);
              }}
              className="bg-purple border-blue disabled:border-purple p-0 border-2 disabled:border-collapse rounded-lg text-center text-white text-lg"
              color="indigo"
              disabled={disabled}
              {...focusProps}
            />
          </div>
        }
      />
    </table>
  );
}
