import React from 'react';


export default function Field({ name, data, bold, money,component,none=false }) {
    return (
      <tr className={"text-sm  text-center " + (money? "bg-purple text-white rounded-lg": "") +  (component? "bg-purple text-white rounded-lg": "")} >
        <td className={"font-medium text-sm md:text-lg p-2 md:p-4"}>{name}</td>
        <td className={"p-2 md:p-4 text-xs  md:text-base max-w-min " + bold + " " }>
          {money && <div className="text-base ">{numberFormat(data)}</div>}
          {component && data}
          {!money && !component && data}
          {none && <div></div>}
        </td>
      </tr>
    );
  }
  
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  